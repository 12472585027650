import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Billpdf from './pages/Billpdf';
import BillpdfWrapper from './pages/Billpdf/Wrapper';

import Recyclepdf from './pages/Recyclepdf';

import App from './App';

const RouteList = (props) =>  {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="billpdf" element={<Billpdf />} />
        <Route path="billpdfwrapper" element={<BillpdfWrapper />} />
        <Route path="recyclepdf" element={<Recyclepdf />} />

      </Routes>
    </BrowserRouter>
  );
}
export default RouteList;