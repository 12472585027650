import React, { useEffect, useState } from 'react';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// 通过 blob 下载
function saveAs(blob, filename) {
  const URL = window.URL || window.webkitURL
  const type = blob.type
  const force_saveable_type = 'application/octet-stream'
  if (type && type !== force_saveable_type) { // 强制下载，而非在浏览器中打开
    const slice = blob.slice || blob.webkitSlice || blob.mozSlice
    blob = slice.call(blob, 0, blob.size, force_saveable_type)
  }
  const url = URL.createObjectURL(blob)
  const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
  save_link.href = url
  save_link.download = filename

  const event = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  })
  save_link.dispatchEvent(event)
  URL.revokeObjectURL(url)
}




const Wrapper = () => {
  const [src, setSrc] = useState('');

  // 根据 file url 获取 blob
function fetchPdfBlob(fileUrl, fileName = 'a.pdf') {
  const oReq = new XMLHttpRequest()
  oReq.open('GET', fileUrl, true)
  oReq.responseType = 'blob'
  oReq.onload = function() {
    const fileBlob = new Blob([oReq.response], {
      type: 'application/pdf'
    })
    console.log('fileBlob:', fileBlob);
    setSrc(fileBlob);
    // saveAs(fileBlob, fileName);
  }
  oReq.send()
}

  useEffect(() => {

    // const url = window.location.href.split('#')[0];
    // const encodedUrl = encodeURIComponent(url);

    // const location = useLocation();
    // const code = location.search.split('code=')[1];

    // console.log('url:', url);

    const url = 'http://mina.owash.me/a.pdf';
    // fetchPdfBlob(url);

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    // xhr 
    xhr.onload = function(e) {
      if (this.status == 200) {
        var file = new Blob([this.response], { type: 'application/pdf' });
        var fileURL = window.URL.createObjectURL(file);
        console.log('fileURL:', fileURL);
        setSrc(fileURL)
      }
    };
    xhr.send(); 
  }, []);

  return (
    <div>
      {/* <iframe
        src="https://oss.owash.cn/billpdf/%E5%A5%A5%E6%96%AF%E6%9B%BC%E5%95%8A_21%E5%B9%B412%E6%9C%88%E8%B4%A6%E5%8D%95_Sy8kx.pdf" 
        frameBorder="0"
      /> */}
      <iframe src={src} />
    </div>
  )
}
export default Wrapper;