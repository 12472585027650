import React, { useEffect } from 'react';

const Recyclepdf = () => {

  var url = '/1.pdf';
  url = 'http://oss.owash.cn/pdf/%E6%94%B6%E8%84%8F%E7%BB%9F%E8%AE%A1%E8%A1%A8_211216-51EEC.pdf?1';

  return (
    <div>
      <a href={ url } download target="_blank">下载</a>
      <iframe src={ url } frameborder="0"></iframe>
    </div>
  )
}
export default Recyclepdf;