import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

const CommonUtils =  {
  defaultDate: '2000-01-01 00:00:00',
  
  today: dayjs().format(dateFormat),
  yesterday: dayjs().subtract(1, 'day').format(dateFormat),
  tomorrow: dayjs().add(1, 'day').format(dateFormat),
  dayAfterTomorrow: dayjs().add(2, 'day').format(dateFormat),

  getDateOptions () {
    const today = dayjs().format(dateFormat);
    const yesterday = dayjs().subtract(1, 'day').format(dateFormat);
    const tomorrow = dayjs().add(1, 'day').format(dateFormat);
    const dayAfterTomorrow = dayjs().add(2, 'day').format(dateFormat);

    let dateOptions = [
      // { label: '昨日', value: 'yesterday', date: yesterday, },
      { label: '今天', value: 'today', date: today, size: 'big', },
      { label: '明天', value: 'tomorrow', date: tomorrow, },
      { label: '后天', value: 'dayAfterTomorrow', date: dayAfterTomorrow, },
    ];
    dateOptions = dateOptions.map(i => {
      i.weekday = this.getWeekDay(i.date);
      i.dateText = dayjs(i.date).format('MM月DD日');
      return i;
    });
    return dateOptions;
  },

  getDateOptionsBeforeDawn () {
    let recycleDateOptions = [
      { 
        value: dayjs().subtract(1, 'day').format('YYYY-MM-DD'), text: '昨天', icon: 'zuotiandedan', 
        bgColor: 'linear-gradient(180deg,#fffbea, #ffeda7)', 
        activeBgColor: 'linear-gradient(to top,#d97708, #f59e0d)',
      },
      { 
        value: dayjs().format('YYYY-MM-DD'), text: '今天', icon: 'jintiandedan', 
        bgColor: 'linear-gradient(180deg,#eff6fe, #cce3ff)', 
        activeBgColor: 'linear-gradient(to top,#3867e2, rgba(96,165,250,0.90))',
      },
    ];
    recycleDateOptions = recycleDateOptions.map(i => {
      i.dateText = dayjs(i.value).format('MM月DD日');
      i.weekday = this.getWeekDay(i.value);
      return i;
    });
    return recycleDateOptions;
  },

  getWeekDay(date) {
    const set = {
      0: '周日',
      1: '周一',
      2: '周二',
      3: '周三',
      4: '周四',
      5: '周五',
      6: '周六',
    };
    const week = dayjs(date).day();
    if (week || week === 0) {
      return set[week] || '错误的日期';
    }
    return '';
  },

  // 根据2地经纬度 获取 距离 km
  getDistance({ pos1, pos2 }) {
    // console.log(pos1, pos2);
    const lat1 = pos1.lat;
    const lng1 = pos1.lng;
    const lat2 = pos2.lat;
    const lng2 = pos2.lng;
  
    var radLat1 = lat1*Math.PI / 180.0;
    var radLat2 = lat2*Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
    Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
    s = s *6378.137 ;// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return parseFloat(s.toFixed(2));
  },

  getFormattedTime ({ time, format = dateFormat }) {
    const today = dayjs().format(dateFormat);
    const yesterday = dayjs().subtract(1, 'day').format(dateFormat);
    const qiantian = dayjs().subtract(2, 'day').format(dateFormat);
    const targetDate = dayjs(time).format(dateFormat);
    const nowTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const diffDay = dayjs(nowTime).diff(time, 'day');
    const diffMin = dayjs(nowTime).diff(time, 'minute');
    const diffSec = dayjs(nowTime).diff(time, 'second');
    if (diffSec < 5) {
      return `刚刚`;
    } else if (diffSec < 30) {
      return `${diffSec}秒前`;
    } else if (diffMin <= 1) {
      return `${diffSec}秒前`;
    } else if (diffMin <= 120) {
      return `${diffMin}分钟前`;
    } else if (today === targetDate) {
      return `今天 ${dayjs(time).format('HH:mm')}`;
    } else if (yesterday === targetDate) {
      return `昨天 ${dayjs(time).format('HH:mm')}`;
    } else if (qiantian === targetDate) {
      return `前天 ${dayjs(time).format('HH:mm')}`;
    } else {
      return `${diffDay}天前`;
    }
    // return dayjs(time).format('MM-DD HH:mm');
  },

  // getUrlParam
  getUrlParam ({ url, key }) {
    // var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)","i");
    // var r = url.split('?')[1].substr(1).match(reg);
    // if (r!=null) return (r[2]); return null;
    url = url.split('?')[1]
    var vars = url.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == key){return decodeURI(pair[1]);}
    }
    return(false);
  },
  // url = 'tpl_aaa&key1=bbb&key2=ccc';
  getAndParam ({ url, key }) {
    const splits = url.split('&');
    // console.log('splits:', splits);
    const v = splits.filter(i => i.indexOf(key) !== -1).length !== 0 ? splits.filter(i => i.indexOf(key) !== -1)[0].split('=')[1] : ''; 
    return v;
  },

  // 是否为纯数字
  isNumber (val) {
    var reg = /^\d{1,}$/
    var pattern = new RegExp(reg);
    return pattern.test(val);
  },
  // 是否为整数
  isInteger (val) {
    return val%1 === 0;
  },
  // 保留小数 2 位小数
  getFormattedPrice (price) {
    // 是否是整数，加上 .00（只在前端加，数据库里存的是 整数 number）
    // 0 只显示 0
    const isInteger = this.isInteger(price);
    // console.log(price,isInteger);
    if (isInteger && price !== 0 && price !== '0') {
      price = `${price}.00`;
    } else {
      price = price.toString();
    }

    // 1.1 变成 1.10
    if (price.split('.').length > 1 && price.split('.')[1].length === 1) {
      price = price + '0';
    }
    return price;
  },
  // 获取 价格 的 元、角信息， 
  // 如传 count， 则还有总价的 元、角信息
  getPriceInfo ({ price, count = 0 }) {
    const i = {
      price,
      count,
    };
    i.priceStr = this.getFormattedPrice(i.price);
    i.tempPrice = i.price;
    i.tempPriceStr = i.priceStr;
    i.priceYuan = i.priceStr.split('.')[0];
    i.priceJiao = i.price !== 0 && i.priceStr.split('.').length > 1 ? `.${i.priceStr.split('.')[1]}` : '';

    const totalPriceInfo = this.getTotalPriceInfo({ price, count });
    // console.log('totalPriceInfo:', price, count, totalPriceInfo);
    for (let key in totalPriceInfo) {
      i[key] = totalPriceInfo[key];
    }
    return i;
  },
  getTotalPriceInfo ({ price, count }) {
    const i = {
      price,
      count,
    };
    i.totalPrice = i.count && i.price ? (i.price * i.count).toFixed(2) : 0;
    i.totalPriceStr = i.count && i.price ? (i.price * i.count).toFixed(2) : 0;
    i.totalPriceYuan = i.totalPrice ? i.totalPrice.split('.')[0] : 0;
    i.totalPriceJiao = i.totalPrice !== 0 && i.totalPrice.split('.').length > 1 ? `.${i.totalPrice.split('.')[1]}` : '';
    return i;
  },
};
export default CommonUtils;
