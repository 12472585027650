import { useEffect } from 'react';
import axios from 'axios';
import wx from 'weixin-js-sdk';
import './App.css';

function App() {

  useEffect(() => {
    const url = window.location.href.split('#')[0];
    const encodedUrl = encodeURIComponent(url);
    console.log('url:', url);
    axios.get(`/api/wxsign?url=${encodedUrl}`)
    .then(function (res) {
      console.log('res:',res);
      const { appid, timestamp, nonceStr, signature } = res.data.data;
// console.log('wx:', wx);
      wx.config({
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appid, // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature,// 必填，签名，见附录1
        jsApiList: [
          "updateAppMessageShareData"
        ]
      });
      wx.ready(function (res) {
        wx.updateAppMessageShareData({
          title: '南苑酒店 - 21年11月账单',
          desc: '记账周期：2021-11-01 ~ 2021-11-31',
          link: url,
          imgUrl: 'https://static.owash.cn/images/pc/pdf_share_img.png',
          success: function () {
            // 用户确认分享后执行的回调函数
            console.log("ok")
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
            console.log("error")
          }
        });
      });
    })
 
  }, []);

  return (
    <div>
      <div className="wrapper">
        <h1 >布凡智洗</h1>
        <div>简单高效的实现布草收送数字化管理</div>
        <img src="https://static.owash.cn/images/qrcode_for_gh.jpg" alt="" style={{ marginTop: 15, marginBottom: 20 }} 
        />
        <div>扫描上方二维码，关注我们的公众号，获取最新资讯</div>
        <div style={{marginTop: 10}}>今日顾问：13116696063（同微信号）</div>
        
      </div>
      <div
        className="footer"
      >
        宁波布凡信息科技有限公司  @2021  
        <a href="https://beian.miit.gov.cn" target="blank" style={{ color: '#999' }}>浙ICP备2021003413号-1</a>
      </div>

    </div>
  );
}

export default App;
